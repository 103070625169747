import { Link } from "gatsby";
import { motion } from "framer-motion";
import React from "react";
import Overlay from "./Overlay";
import { GatsbyImage } from "gatsby-plugin-image";
import makeUrl from "../../utils/url";

const menuItem = {
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
    x: -20,
  },
  open: (key) => ({
    opacity: 1,
    transition: {
      delay: 0.25 + key * 0.1,
      type: "tween",
    },
    x: 0,
  }),
};

const MenuMobile = ({ links, isOpen, setIsOpen, data }) => {
  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="container flex flex-col justify-center">
        <ul className="text-center">
          {links.map((link, key) => (
            <motion.li
              className="my-3"
              animate={isOpen ? "open" : "closed"}
              custom={key}
              key={`menu_mobile_link${key}`}
              variants={menuItem}
            >
              <Link
                className="font-display text-4xl text-gray-600"
                to={`/${link.title.toLowerCase()}`}
                onClick={() => setIsOpen(false)}
              >
                {link.title}
              </Link>
              {link.subMenuEntries && subMenu(link.subMenuEntries, data)}
            </motion.li>
          ))}
        </ul>
      </div>
    </Overlay>
  );
};

const subMenu = (parentData, data) =>
  parentData
    .sort((a, b) => a.order - b.order)
    .map((sublink, i) => (
      <Link
        key={i}
        to={`/${
          sublink.title.toLowerCase() === "alle"
            ? "geschenkideen"
            : sublink.title.toLowerCase()
        }`}
      >
        <li
          key={i}
          className="flex flex-wrap items-center justify-center p-2 rounded-md"
        >
          <div className="text-2xl font-display relative">
            {sublink.title}
            <div className="h-8 w-8 -ml-3 absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-full">
              <GatsbyImage
                image={sublink.icon.localFile.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </li>
        {subSubMenu(data, sublink.title)}
      </Link>
    ));

const subSubMenu = (data, titleParent) => {
  let subsubLinks = null;
  let urlPrefix = null;

  if (titleParent === "Person") {
    subsubLinks = data.allContentfulPerson.nodes.map(
      (node) => node.title.title
    );
    urlPrefix = "person";
  }
  if (titleParent === "Anlass") {
    subsubLinks = data.allContentfulAnlass.nodes.map((node) => node.title);
    urlPrefix = "anlass";
  }

  if (subsubLinks) {
    return (
      <div className="p-2 ">
        <ul className="grid grid-cols-3">
          {subsubLinks.map((subsub, i) => (
            <Link key={i * 10 + 10} to={`/${makeUrl(subsub, urlPrefix)}`}>
              <li key={i * 10 + 10} className="text-gray-500">
                {subsub}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    );
  }
};

export default MenuMobile;
