import React, { useEffect, useState } from "react";
import Link from "gatsby-link";
import makeUrl from "../../utils/url";
import { Search2Icon } from "@chakra-ui/icons";

const QUERY = (keyword) => `{
  ideeCollection(limit: 200, where: {OR : [{title_contains: "${keyword}"},{description_contains: "${keyword}"} ]}) {
    items {
      title
      description
      imageCollection(limit: 1) {
        items {
          url(transform: {width: 300})
        }
      }
    }
  }
}`;

function SearchDisplay({ items, focus, setKeyword }) {
  return (
    <div
      className={`absolute shadow-md bg-white p-4 z-50 rounded-lg ${
        !focus
          ? "hidden"
          : !items
          ? "hidden"
          : !items.length
          ? "hidden"
          : "block"
      }`}
      onClick={() => setKeyword("Suchen..")}
      onKeyDown={() => setKeyword("Suchen..")}
      role="button"
      tabIndex={0}
    >
      {items?.map((item, i) => (
        <div key={i}>
          <Link
            to={`/${makeUrl(item.title, "idee")}`}
            className="flex items-center"
          >
            <div className="h-20 w-20">{item.image}</div>
            <div className="w-96 p-2 ml-2  hover:bg-orange hover:bg-opacity-10 transition duration-300 rounded-lg">
              <div className="font-display">{item.title}</div>
              <div className="text-gray-600">
                {item.description.slice(0, 70).replace("#", "")}...
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}

function Search() {
  const [keyword, setKeyword] = useState("");
  const [focus, setFocus] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      window
        .fetch(
          `https://graphql.contentful.com/content/v1/spaces/rkj7wul4lgic/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer bsL0XMuEFiswVO4MWlIHv-jsQjtn-u_7G4lnvnBe0Is",
            },
            body: JSON.stringify({ query: QUERY(keyword) }),
          }
        )
        .then((response) => response.json())
        .then(({ data, errors }) => {
          if (errors) {
            console.error(errors);
          }
          setData(data);
        });
    };

    document.addEventListener("keyup", fetchData, { passive: true });
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("keyup", fetchData);
    };
  }, [keyword]);

  var items = processIdeas(data?.ideeCollection?.items);
  // sort items higher if keyword in title
  items = items
    ?.sort(
      (a, b) =>
        b.title.toLowerCase().includes(keyword.toLowerCase()) -
        a.title.toLowerCase().includes(keyword.toLowerCase())
    )
    .splice(0, 20);

  return (
    <div className="pt-6 md:pt-10 hidden lg:block">
      <div
        className={`border-2 p-2 px-3 rounded-full flex items-center align-top ${
          focus && "shadow-md  border-orange"
        }`}
      >
        <input
          className={`w-64 ${focus && "w-80"} outline-none bg-transparent`}
          style={{ transition: "width 0.5s ease" }}
          key="search"
          value={keyword}
          placeholder={"Alles beginnt mit einer Idee.."}
          onChange={(e) => setKeyword(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <span className="-mt-1.5">
          <Search2Icon />
        </span>
      </div>

      {/* BACKGROUND ELEMENT DAS DAZU FÜHRT DASS SUCHE GESCHLOSSEN WIRD */}
      {keyword && (
        <div
          className="absolute w-full h-full bg-transparent left-3 right-3 opacity-0 z-50"
          onClick={() => setKeyword("")}
          onKeyDown={() => setKeyword("")}
          role="button"
          tabIndex={0}
          aria-label="leave search"
        />
      )}

      {/* SUCHANZEIGE */}
      <SearchDisplay
        items={items}
        setFocus={setFocus}
        setKeyword={setKeyword}
        focus={keyword}
      />
    </div>
  );
}

function processIdeas(ideas) {
  return ideas?.map((idea) => ({
    title: idea.title,
    description: idea.description,
    image: (
      <img
        className="object-cover object-center w-full h-full rounded-lg"
        src={idea.imageCollection.items[0].url}
        alt="pic"
      />
    ),
  }));
}

export default Search;
