import { graphql, Link, StaticQuery } from "gatsby";
import React, { useState } from "react";
import MenuMobile from "./MenuMobile";
import { FaBars } from "react-icons/fa";
import { GatsbyImage } from "gatsby-plugin-image";
import makeUrl from "../../utils/url";
import Search from "./Search";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          allContentfulMenuEntry(sort: { fields: order }) {
            nodes {
              title
              subMenuEntries {
                title
                description
                order
                icon {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          allContentfulPerson {
            nodes {
              title {
                title
              }
            }
          }
          allContentfulAnlass(sort: { order: ASC, fields: date }) {
            nodes {
              title
            }
          }
          allContentfulIdee {
            nodes {
              title {
                title
              }
              description {
                description
              }
              image {
                gatsbyImageData
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="container-xl pt-6 pb-12 md:pt-8">
          <div className="flex justify-between items-center">
            <Link to="/">
              <img alt="Logo" className="w-44 mt-6" src="/logo.png" />
            </Link>
            <Search ideas={data.allContentfulIdee.nodes} />
            <button
              className="sm:hidden flex pt-12"
              onClick={() => setIsMenuOpen(true)}
              aria-label="Open Menu"
            >
              <FaBars className="h-6 w-auto text-gray-900 fill-current -mt-1" />
            </button>
            <MenuMobile
              links={data.allContentfulMenuEntry.nodes}
              isOpen={isMenuOpen}
              setIsOpen={setIsMenuOpen}
              data={data}
            />
            <div className="hidden sm:block pt-12">
              {data.allContentfulMenuEntry.nodes &&
                data.allContentfulMenuEntry.nodes.map((link, key) => (
                  <div
                    key={`menu_desktop_link${key}`}
                    className="group relative inline-block pb-2 pl-8"
                  >
                    <Link
                      key={`menu_desktop_link${key}`}
                      className="text-display font-display text-sm sm:text-lg font-medium px-px border-transparent text-gray-600 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out"
                      activeClassName="text-gray-600"
                      to={`/${link.title.toLowerCase()}`}
                    >
                      {link.title}
                      {link.subMenuEntries && (
                        <span className="ml-2">
                          <i className="arrow down transform" />
                        </span>
                      )}
                    </Link>
                    {link.subMenuEntries && (
                      <ul className="z-40 shadow-centered p-2 rounded-lg absolute bg-white top-1 left-1/2 w-120 hidden group-hover:block transform -left-4/3 lg:-left-4/3 xl:-left-4/3">
                        {subMenu(link.subMenuEntries, data, link.title)}
                      </ul>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    />
  );
};

const subMenu = (parentData, data) =>
  parentData
    .sort((a, b) => a.order - b.order)
    .map((sublink, i) => (
      <div key={i} className="z-40">
        <Link
          key={i}
          to={`/${
            sublink.title.toLowerCase() === "alle"
              ? "geschenkideen"
              : sublink.title.toLowerCase()
          }`}
        >
          <li
            key={i}
            className="flex flex-no-wrap items-center hover:bg-orange hover:bg-opacity-10 p-2 rounded-md transition duration-300"
          >
            <div className="h-10 w-10 mr-2">
              <GatsbyImage
                image={sublink.icon.localFile.childImageSharp.gatsbyImageData}
                alt={"menu item"}
              />
            </div>
            <span>
              <span className="font-bold">{sublink.title} </span>
              <br />
              <span className="text-gray-600">{sublink.description}</span>
            </span>
          </li>
        </Link>
        {subSubMenu(data, sublink.title)}
      </div>
    ));

const subSubMenu = (data, titleParent) => {
  let subsubLinks = null;
  let urlPrefix = null;

  if (titleParent === "Person") {
    subsubLinks = data.allContentfulPerson.nodes.map(
      (node) => node.title.title
    );
    urlPrefix = "person";
  }
  if (titleParent === "Anlass") {
    subsubLinks = data.allContentfulAnlass.nodes.map((node) => node.title);
    urlPrefix = "anlass";
  }

  if (subsubLinks) {
    return (
      <div className="p-2 ml-12">
        <ul className="grid grid-cols-3">
          {subsubLinks.map((subsub, i) => (
            <Link key={i * 10 + 10} to={`/${makeUrl(subsub, urlPrefix)}`}>
              {subsub}
              <span className="ml-1.5">
                <i className="arrow-sm right" />
              </span>
            </Link>
          ))}
        </ul>
      </div>
    );
  }
};

export default Header;
