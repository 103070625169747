export default function makeUrl(value, type) {
  value = value.toLowerCase();
  value = value.replace(/ä/g, "ae");
  value = value.replace(/ö/g, "oe");
  value = value.replace(/ü/g, "ue");
  value = value.replace(/ß/g, "ss");
  value = value.replace(/ /g, "-");
  value = value.replace(/\./g, "");
  value = value.replace(/,/g, "");
  value = value.replace("\n", "");
  value = value.replace(/\(/g, "");
  value = value.replace(/\)/g, "");
  value = value.replace(/["'“„]/g, "");
  value = value.replace("?", "");
  return type ? `${type}/${value}` : `${value}`;
}
