import PropTypes from "prop-types";
import React from "react";
import "typeface-inter";
import "../styles/style.css";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import CookieConsent from "react-cookie-consent";
import "@fontsource/lora";
import "@fontsource/lora/500.css";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      {children}
      <Footer />
      <CookieConsent
        enableDeclineButton
        cookieName="gatsby-gdpr-google-analytics"
        disableStyles={true}
        buttonText="Cookies zulassen"
        declineButtonText="Cookies unterbinden"
        buttonClasses="bg-green-700 hover:bg-green-800 rounded-md p-2 text-white"
        declineButtonClasses="bg-red-500 hover:bg-red-600 rounded-md p-2 text-white mx-2"
        containerClasses="bottom-0 bg-gray-100 left-0 right-0 w-full fixed p-3 flex flex-wrap items-center justify-center lg:justify-end z-50"
        buttonWrapperClasses="py-2 lg:pt-0 lg:ml-auto flex"
      >
        Wir verwenden Cookies, um Zugriffe auf unsere Website zu analysieren.
      </CookieConsent>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
};

export default Layout;
