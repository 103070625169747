import { Link } from "gatsby";
import React from "react";
import BMC from "../../static/bmc_small.png";

const Footer = () => {
  return (
    <div className="w-full bg-gray-100">
      <div className="container-xl py-12 md:flex md:items-center md:justify-between mt-auto ">
        <div className="mt-8 md:mt-0 text-gray-500">
          <span className="text-center text-sm md:text-base ">
            &copy; 2021 Ideenfreude
          </span>
          <span className="ml-8">
            <Link to="/impressum">Impressum</Link>
          </span>
        </div>
        <div className="">
          <div className="py-4 font-display md:w-72  text-gray-500">
            Falls Dir unsere Seite gefällt, würden wir uns über eine Spende
            freuen!
          </div>
          <a
            href="https://www.buymeacoffee.com/handverlesen"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-48 rounded-lg" alt="Buy me a coffee" src={BMC} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
